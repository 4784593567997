import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButton from "../components/share-button"
import BtnCircle from "../components/button-circle"
import GalleryCategoryTilesMobile from "../components/gallery-category-tiles-mobile"
import GalleryCategoryTiles from "../components/gallery-category-tiles"
import ContactForm from "../components/form"

const PageNotFound = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/kontakt/" }) {
        frontmatter {
          title
        }
        html
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/(blog)/" }
          frontmatter: { inGallery: { eq: true } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 10
      ) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  `)
  const posts = pageData.allMarkdownRemark.nodes
  const pageTitle = pageData.markdownRemark.frontmatter.title
  const pageBody = pageData.markdownRemark.html
  const featuredImage = getImage(
    pageData.markdownRemark.frontmatter.featuredImage
  )

  return (
    <Layout location={location} title={pageTitle}>
      <div className="section">
        <SEO title="Błąd 404: Niestety taka strona nie istnieje." />
        <div className="columns">
          <div className="column is-8-desktop is-12-tablet content-container has-background-dark is-6">
            <div className="is-flex is-justify-content-flex-end is-align-content-center mb-2 social-share-container">
              <ShareButton location={location} />
            </div>
            <section className="pt-3">
              <h2 className="title is-3 is-family-secondary has-text-weight-light has-letter-spacing-1 is-title-underlined is-relative">
                BŁĄD 404
              </h2>
            </section>
            <p className="is-family-secondary mb-1 is-size-6 has-text-weight-light has-letter-spacing-1 mt-6 is-uppercase">
              Niestety taka strona nie istnieje.
            </p>
            <section className="is-hidden-desktop">
              <GalleryCategoryTilesMobile />
            </section>
          </div>
          <div className="column is-offset-1 is-3 is-1-tablet is-hidden-touch">
            <GalleryCategoryTiles />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
